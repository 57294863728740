import React from "react";

const Test = () => {
  React.useEffect(() => {
    const $ctr = document.getElementById("player");

    const opts = {
      value: `
        
        <miraml>
      <canvas width="1280" height="720">
        <spine>
          <!-- 第一幕，0~2秒 -->
          <video x="50vw" y="50vh" width="100vw" src="/1.mp4" duration="4" ss="100">
            <!-- <image zIndex="2" x="50rpx" y="30rpx" height="50rpx" width="50rpx" src="pic1.jpg"></image> -->
          </video>
          <!-- 转场1 -->
          <trans key="WaterWave"></trans>
          <!-- 第二幕，2~4秒 -->
          <video x="50vw" y="50vh" width="100vw" src="/1.mp4" duration="2" ss="8">
            <!-- <image zIndex="2" x="100rpx" y="30rpx" height="50rpx" width="50rpx" src="./1.png"></image> -->
          </video>
          <!-- 转场2 -->
          <trans key="cube"></trans>
          <!-- 第三幕，4~6秒 -->
          <scene name="复合片段">
            <video x="50vw" y="50vh" width="100vw" src="/1.mp4" duration="2" ss="15">
              <!-- <image zIndex="2" x="150rpx" y="30rpx" height="50rpx" width="50rpx" src="pic3.jpg"></image> -->
            </video>
          </scene>
          <!-- 转场3 -->
          <trans key="cube"></trans>
          <!-- 第四幕，6~8秒 -->
          <video x="50vw" y="50vh" width="100vw" src="/1.mp4" duration="2" ss="20"></video>
        </spine>
      </canvas>
    </miraml>
        `,

      // 以下是相关配置及默认值
      debug: false, // 打开加载过程中的一些log
      cache: true, // 缓存素材(font/gif/image)

      // 播放相关
      volume: 1.0, // 音量，0-1，*暂时不支持
      playbackRate: 1.0, // 播放速度

      // UI相关
      majorColor: "#4d75b4", // 主色，建议深色
      controlBgColor: "#FFF", // 控件背景色，建议浅色，或跟主色调相反
      playerBgColor: "#000", // 播放器背景色，建议黑色

      // 控件功能
      timeRemain: true, // 控制条右侧显示时间为 true:当前剩余时长 false:总时长
      decimal: 0, // 时间相关的显示（单位:秒），保留几位小数，0就是只精确到秒
      hideControls: "auto", // 控制条隐藏 true:一直隐藏 false:一直显示 'auto':根据鼠标自动显示/隐藏
      hideMiniBar: false, // 是否在控制条隐藏的时候显示最下方的小进度条 true:隐藏 false:显示
      cover: "auto", // 是否显示大播放按钮 true:暂停时显示 false:不显示 'auto':仅在首次播放前显示
      keyboardPlay: true, // 是否响应键盘事件 true:响应 false: 不响应 (空格暂停/播放，左右键seek)
      keySeekStep: 1, // 键盘左右键seek的时间步长(秒)，默认单步1秒
      tapPlay: true, // 是否响应屏幕点击 true:响应 false: 不响应 (点击播放区域暂停/播放)
    };
    const player = window.miraplayer.create($ctr, opts);
  }, []);
  return (
    <div>
      <div
        id="player"
        style={{
          width: "1200px",
          height: "720px",
        }}
      ></div>
    </div>
  );
};

export default Test;
