// URL_Channel 忽略列表,列表中的内容不会被视为 URL_Channel 的一部分
import { urlChannelIgnoreList } from "../data";
// 服务器配置主域名
const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN || currentPageURL.hostname;
// 是否启用子域名
const USER_SUB_DOMAIN = process.env.REACT_APP_USE_SUB_DOMAIN === "true";
// URL_Channel 投放规则,检查 URL_Channel 的正则表达式
const CHANNEL_RULE = process.env.REACT_APP_CHANNEL_RULE || /^\/f\/[^\/]+|^\/f/;
/**
 * 多域名处理函数,完成路径子域名替换,并保证路径中 URL_Channel 不变
 * @param {String} newPath 新路径,相对路径或完整路径
 * @param {String} subDomain 子域名,目标路径的子域名
 * @returns {String} newHref 新路径,完整路径
 */
export function navigate(newPath = "", subDomain = null) {
  try {
    // 获取当前网页 URL 对象
    const currentPageURL = new URL(window.location.href);
    // 路径为空,返回当前路径
    if (!!!newPath) {
      return currentPageURL.href;
    }
    // 检查是否为完整路径
    const isFullURL = !!newPath.match(/^https?:\/\//);
    // 新路径如果为完整路径,则覆盖当前页面路径
    if (isFullURL) {
      // 新路径如果为完整路径且不更改域名(子域名为空),直接返回新路径
      if (!!!subDomain) return newPath;
      currentPageURL.href = newPath;
    } else {
      // 检查 URLCannel
      const urlChannel = checkUrlChannel(currentPageURL.href);
      // 清空不必要的参数
      currentPageURL.pathname = "";
      currentPageURL.search = "";
      currentPageURL.hash = "";
      // 拼接路径
      currentPageURL.pathname = !!urlChannel ? urlChannel + newPath : newPath;
    }
    // 修改子域名
    const newHref = USER_SUB_DOMAIN
      ? changeDomain(subDomain, currentPageURL.href)
      : currentPageURL.href;
    // 返回新地址
    return newHref;
  } catch (e) {
    return "";
  }
}
/**
 * 检查 URLCannel 函数
 * @param {String} href 需要检查的路径,完整路径
 * @returns {String} urlChannel 检测到的 URLCannel
 */
export function checkUrlChannel(href = "") {
  try {
    const url = new URL(href || window.location.href);
    // 捕获路径中 URL_Channel ,如果没有返回 ''
    const [urlChannel] = url.pathname.match(CHANNEL_RULE) || [""];
    // 检查当前 URL_Channel 是否在忽略列表中
    const ignore = urlChannelIgnoreList.find((path) =>
      urlChannel.toLowerCase().includes(path.toLowerCase()),
    );
    return ignore ? urlChannel.toLowerCase().replace(ignore.toLowerCase(), "") : urlChannel;
  } catch (e) {
    return "";
  }
}
/**
 * 修改子域名函数
 * @param {String} subDomain 子域名,将替换现有子域名
 * @param {String} href 需要修改的路径,完整路径
 * @returns {String} newHref 修改后的路径,完整路径
 */
export function changeDomain(subDomain = null, href = "") {
  try {
    const url = new URL(href || window.location.href);
    // 根据 isAddDomain 判断添加(true)或修改(false)子域名
    url.hostname =
      subDomain !== null ? `${subDomain ? subDomain + "." : ""}${ROOT_DOMAIN}` : url.hostname;
    // 返回修改后的地址
    return url.href;
  } catch (e) {
    return "";
  }
}
/**
 * 检测首页
 * @param {String} href 需要检查的路径,完整路径
 * @returns {Boolean} isHome 路径是否为首页
 */
export function homeCheck(href = "") {
  const url = new URL(href || window.location.href);
  // 检查首页正则表达式,规则与 URL_Channel 略有区别,首页必须以 '/' 结尾
  const homeCheckRegexp = /^\/f\/[^\/]+\/?$|^\/f\/?$|^\/$/;
  // 检查是否满足首页规则
  const isHome = homeCheckRegexp.test(url.pathname);
  // 捕获路径中 URL_Channel ,如果没有返回 ''
  const [urlChannel] = url.pathname.match(CHANNEL_RULE) || [""];
  // 检查当前 URL_Channel 是否在忽略列表中
  const ignore = urlChannelIgnoreList.find((path) =>
    urlChannel.toLowerCase().includes(path.toLowerCase()),
  );
  return ignore ? false : isHome;
}
