export const subscribe = {
  syncSubPlanUrl: "/sub/getPlan",
  syncUserSubStateUrl: "/sub/subState",
  verifyUserSubIdUrl: "/sub/verifySub",
  suspendUserSubUrl: "/sub/suspend/",
  renewUserSubUrl: "/sub/renew/",
};
export const user = {
  loginUserUrl: "/user/login",
  forgetUserPasswordUrl: "/user/resetPassSubmit",
  saveResetUserPasswordUrl: "/user/updatePassword",
  saveResetUrl: "/user/resetPassSave",
  registerUserUrl: "/user/register",
  verifyLoginTokenUrl: "/user/profile",
};

export const convert = {
  createFileConvertJobUrl: "/phototask/checkPhotoTask",
  checkConvertJobStateUrl: "/phototask/checkPhotoTask",
  getConvertKeyUrl: "/phototask/createPhotoTask",
};

export const share = {
  getShareFileUrl: "/share/uploadurl",
  createShareFileUrl: "/share/createLink",
  getFileFromShareUrl: "/share/view",
};
export const templates = {
  templatesUrl: "https://photo2tools.com/api/photo2tools/search_template",
};
export default { subscribe, user, convert, share, templates };
