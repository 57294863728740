// 获取当前路径
const nowPAge = window.location;
// 获取根(主)域名
const ROOT_DOMAIN = process.env.REACT_APP_ROOT_DOMAIN;
// 生成跨域存储地址,localhost 路径以外使用根(主)域名
const storageWeb = `${nowPAge.protocol}//${
  nowPAge.host.includes("localhost") ? "localhost" : ROOT_DOMAIN
}${nowPAge.port ? ":" + nowPAge.port : ""}/static/storage.html`;
const storage = new CrossStorageClient(storageWeb);
export default storage;
