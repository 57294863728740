// 全局路由管理文件
// REACT
import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
// COMPONENT
import Home from "../page/Home";
import Loading from "../page/toolPage/component/Loading";

const PhotoEditor = React.lazy(() => import("../page/toolPage/PhotoEditor"));
const CropPhoto = React.lazy(() => import("../page/toolPage/CropPhoto"));
const ResizePhoto = React.lazy(() => import("../page/toolPage/ResizePhoto"));
const RotatePhoto = React.lazy(() => import("../page/toolPage/RotatePhoto"));
const PhotoBackgroundRemove = React.lazy(() => import("../page/toolPage/PhotoBackgroundRemove"));
const PhotoEnlarge = React.lazy(() => import("../page/toolPage/PhotoEnlarge"));
const PhotoRemoveRain = React.lazy(() => import("../page/toolPage/PhotoRemoveRain"));
const PhotoDenoise = React.lazy(() => import("../page/toolPage/PhotoDenoise"));
const ColorBlackAndWhitePhoto = React.lazy(() =>
  import("../page/toolPage/ColorBlackAndWhitePhoto"),
);
const PhotoToCartoon = React.lazy(() => import("../page/toolPage/PhotoToCartoon"));
const PhotoToAnime = React.lazy(() => import("../page/toolPage/PhotoToAnime"));
const AddStickerToPhoto = React.lazy(() => import("../page/toolPage/AddStickerToPhoto"));
const AddTextToPhoto = React.lazy(() => import("../page/toolPage/AddTextToPhoto"));
const DrawPhoto = React.lazy(() => import("../page/toolPage/DrawPhoto"));
const AddFrameToPhoto = React.lazy(() => import("../page/toolPage/AddFrameToPhoto"));
const PhotoCompress = React.lazy(() => import("../page/toolPage/PhotoCompress"));
const ChangeFaceAge = React.lazy(() => import("../page/toolPage/ChangeFaceAge"));
const ChangeFaceGender = React.lazy(() => import("../page/toolPage/ChangeFaceGender"));
const PhotoCollageWorkSpace = React.lazy(() => import("../page/toolPage/PhotoCollageWorkSpace"));
const PhotoCollage = React.lazy(() => import("../page/toolPage/PhotoCollage"));
const PhotoToBmp = React.lazy(() => import("../page/toolPage/PhotoToBmp"));
const PhotoToJpg = React.lazy(() => import("../page/toolPage/PhotoToJpg"));
const PhotoToPng = React.lazy(() => import("../page/toolPage/PhotoToPng"));
const PhotoToTif = React.lazy(() => import("../page/toolPage/PhotoToTif"));
const PhotoToTga = React.lazy(() => import("../page/toolPage/PhotoToTga"));
const PhotoToPcx = React.lazy(() => import("../page/toolPage/PhotoToPcx"));
const PhotoToWebp = React.lazy(() => import("../page/toolPage/PhotoToWebp"));
const PhotoToAvif = React.lazy(() => import("../page/toolPage/PhotoToAvif"));
const PhotoToWMF = React.lazy(() => import("../page/toolPage/PhotoToWMF"));
const PhotoToRaw = React.lazy(() => import("../page/toolPage/PhotoToRaw"));
const PhotoTemplates = React.lazy(() => import("../page/toolPage/PhotoTemplates"));
const PhotoEditorWorkSpace = React.lazy(() => import("../page/toolPage/PhotoEditorWorkSpace"));
//导入
const ForgetPassWeb = React.lazy(() => import("../page/component/User/ForgetPassWeb"));
const Result = React.lazy(() => import("../page/Result"));
const Profile = React.lazy(() => import("../page/Profile/Profile"));
const ProfileContent = React.lazy(() => import("../page/Profile/ProfileContent"));
const ResetPasswordByOldPassword = React.lazy(() =>
  import("../page/Profile/ResetPasswordByOldPassword"),
);
const SubscribePage = React.lazy(() => import("../page/subscribe/SubscribePage"));
const Support = React.lazy(() => import("../page/Support"));
const MiddlePageOfConvert = React.lazy(() => import("../page/toolPage/MiddlePageOfConvert"));
const Lix = React.lazy(() => import("../text/lix.js"));
export default function Router() {
  return (
    <BrowserRouter>
      {/* 路由模式 */}
      <Routes history={history}>
        {/* 主页 */}
        <Route path="/" element={<Home />} />

        <Route path=":tag/" element={<Home />} />
        <Route path=":tag1/:tag2/" element={<Home />} />
        <Route
          path=":tag1/convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path="/Lix"
          element={
            <React.Suspense fallback={<Loading />}>
              <Lix />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/convert"
          element={
            <React.Suspense fallback={<Loading />}>
              <MiddlePageOfConvert />
            </React.Suspense>
          }
        />
        <Route
          path="/editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEditorWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEditorWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEditorWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/editor"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEditorWorkSpace />
            </React.Suspense>
          }
        />
        {/* //照片编辑页 */}
        <Route
          path="/edit-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEditor />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/edit-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEditor />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/edit-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEditor />
            </React.Suspense>
          }
        />
        {/* //照片裁剪页 */}
        <Route
          path="/crop-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/crop-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/crop-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <CropPhoto />
            </React.Suspense>
          }
        />
        {/* //照片尺寸调整页 */}
        <Route
          path="/resize-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizePhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/resize-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizePhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/resize-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ResizePhoto />
            </React.Suspense>
          }
        />
        {/* //旋转照片页 */}
        <Route
          path="/rotate-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/rotate-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/rotate-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <RotatePhoto />
            </React.Suspense>
          }
        />
        {/* //照片背景移除 */}
        <Route
          path="/remove-background"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoBackgroundRemove />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/remove-background"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoBackgroundRemove />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/remove-background"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoBackgroundRemove />
            </React.Suspense>
          }
        />
        {/* //照片超分 */}
        <Route
          path="/remove-blur"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEnlarge />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/remove-blur"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEnlarge />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/remove-blur"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoEnlarge />
            </React.Suspense>
          }
        />
        {/* //照片去雨 */}
        <Route
          path="/remove-raindrop"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoRemoveRain />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/remove-raindrop"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoRemoveRain />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/remove-raindrop"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoRemoveRain />
            </React.Suspense>
          }
        />
        {/* //照片去噪 */}
        <Route
          path="/remove-noise"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoDenoise />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/remove-noise"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoDenoise />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/remove-noise"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoDenoise />
            </React.Suspense>
          }
        />
        {/* //黑白照片上色 */}
        <Route
          path="/colorize-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ColorBlackAndWhitePhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/colorize-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ColorBlackAndWhitePhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/colorize-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <ColorBlackAndWhitePhoto />
            </React.Suspense>
          }
        />
        {/* //照片卡通化 */}
        <Route
          path="/photo-to-cartoon"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToCartoon />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-cartoon"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToCartoon />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-cartoon"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToCartoon />
            </React.Suspense>
          }
        />
        {/* //照片动漫化 */}
        <Route
          path="/photo-to-anime"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToAnime />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-anime"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToAnime />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-anime"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToAnime />
            </React.Suspense>
          }
        />
        {/* //添加贴纸 */}
        <Route
          path="/stickers"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddStickerToPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/stickers"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddStickerToPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/stickers"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddStickerToPhoto />
            </React.Suspense>
          }
        />
        {/* //添加文本 */}
        <Route
          path="/texts"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddTextToPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/texts"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddTextToPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/texts"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddTextToPhoto />
            </React.Suspense>
          }
        />
        {/* //照片绘画 */}
        <Route
          path="/draw"
          element={
            <React.Suspense fallback={<Loading />}>
              <DrawPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/draw"
          element={
            <React.Suspense fallback={<Loading />}>
              <DrawPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/draw"
          element={
            <React.Suspense fallback={<Loading />}>
              <DrawPhoto />
            </React.Suspense>
          }
        />
        {/* //添加相框 */}
        <Route
          path="/frame"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddFrameToPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/frame"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddFrameToPhoto />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/frame"
          element={
            <React.Suspense fallback={<Loading />}>
              <AddFrameToPhoto />
            </React.Suspense>
          }
        />
        {/* //照片压缩 */}
        <Route
          path="/compress-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCompress />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/compress-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCompress />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/compress-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCompress />
            </React.Suspense>
          }
        />
        {/* 年龄修改 */}
        <Route
          path="/ChangeFaceAge"
          element={
            <React.Suspense fallback={<Loading />}>
              <ChangeFaceAge />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ChangeFaceAge"
          element={
            <React.Suspense fallback={<Loading />}>
              <ChangeFaceAge />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ChangeFaceAge"
          element={
            <React.Suspense fallback={<Loading />}>
              <ChangeFaceAge />
            </React.Suspense>
          }
        />
        {/* 性别转换 */}
        <Route
          path="/ChangeFaceGender"
          element={
            <React.Suspense fallback={<Loading />}>
              <ChangeFaceGender />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ChangeFaceGender"
          element={
            <React.Suspense fallback={<Loading />}>
              <ChangeFaceGender />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ChangeFaceGender"
          element={
            <React.Suspense fallback={<Loading />}>
              <ChangeFaceGender />
            </React.Suspense>
          }
        />

        {/* //拼图 */}
        <Route
          path="/collage-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCollage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/collage-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCollage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/collage-photo"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCollage />
            </React.Suspense>
          }
        />
        {/* //拼图工作区*/}
        <Route
          path="/collage"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCollageWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/collage"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCollageWorkSpace />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/collage"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoCollageWorkSpace />
            </React.Suspense>
          }
        />
        {/* //photo to bmp */}
        <Route
          path="/photo-to-bmp"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToBmp />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-bmp"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToBmp />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-bmp"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToBmp />
            </React.Suspense>
          }
        />
        {/* //photo to jpg */}
        <Route
          path="/photo-to-jpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToJpg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-jpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToJpg />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-jpg"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToJpg />
            </React.Suspense>
          }
        />
        {/* //photo to png */}
        <Route
          path="/photo-to-png"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToPng />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-png"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToPng />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-png"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToPng />
            </React.Suspense>
          }
        />
        {/* //photo to tif */}
        <Route
          path="/photo-to-tif"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToTif />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-tif"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToTif />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-tif"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToTif />
            </React.Suspense>
          }
        />
        {/* //photo to tga */}
        <Route
          path="/photo-to-tga"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToTga />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-tga"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToTga />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-tga"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToTga />
            </React.Suspense>
          }
        />
        {/* //photo to pcx */}
        <Route
          path="/photo-to-pcx"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToPcx />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-pcx"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToPcx />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-pcx"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToPcx />
            </React.Suspense>
          }
        />
        {/* //photo to webp */}
        <Route
          path="/photo-to-webp"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToWebp />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-webp"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToWebp />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-webp"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToWebp />
            </React.Suspense>
          }
        />
        {/* //photo to avif */}
        <Route
          path="/photo-to-avif"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToAvif />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-avif"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToAvif />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-avif"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToAvif />
            </React.Suspense>
          }
        />
        {/* //photo to WMF */}
        <Route
          path="/photo-to-WMF"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToWMF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-WMF"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToWMF />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-WMF"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToWMF />
            </React.Suspense>
          }
        />
        {/* //photo to raw */}
        <Route
          path="/photo-to-raw"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToRaw />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-to-raw"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToRaw />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-to-raw"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoToRaw />
            </React.Suspense>
          }
        />
        {/* //template */}
        <Route
          path="/photo-templates"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoTemplates />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/photo-templates"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoTemplates />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/photo-templates"
          element={
            <React.Suspense fallback={<Loading />}>
              <PhotoTemplates />
            </React.Suspense>
          }
        />
        {/* 结果页 */}
        <Route
          path="/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path="/Share/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/:tag3/:tag4/Convert/Result"
          element={
            <React.Suspense fallback={<Loading />}>
              <Result />
            </React.Suspense>
          }
        />
        <Route
          path="/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Subscribe"
          element={
            <React.Suspense fallback={<Loading />}>
              <SubscribePage />
            </React.Suspense>
          }
        />
        <Route
          path="/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path=":tag/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path=":tag1/:tag2/Profile"
          element={
            <React.Suspense fallback={<Loading />}>
              <Profile />
            </React.Suspense>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
          <Route
            path="resetPasswordByOldPassword"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordByOldPassword />
              </React.Suspense>
            }
          />
          <Route
            path="plan"
            element={
              <React.Suspense fallback={<Loading />}>
                <ProfileContent />
              </React.Suspense>
            }
          />
        </Route>
        {/* Support页面 */}
        <Route
          path="/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/Support"
          element={
            <React.Suspense fallback={<Loading />}>
              <Support />
            </React.Suspense>
          }
        />
        {/* 忘记密码 */}
        <Route
          path="/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        <Route
          path=":tag/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        <Route
          path=":tag1/:tag2/ForgetPassWeb"
          element={
            <React.Suspense fallback={<Loading />}>
              <ForgetPassWeb />
            </React.Suspense>
          }
        />
        {/* 找不到页面 */}
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
