import { toolList } from "./tools";
/**
 * 获取工具
 * @param {Number} identification 工具ID
 * @param {String} identification 工具名称
 * @param {undefined} identification 自动通过地址获取
 * @returns {Object} tool
 */
export function getTool(identification) {
  try {
    switch (typeof identification) {
      case "undefined": {
        const currentPage = new URL(window.location.href);
        const toolPath = currentPage.pathname;

        const matchToolsByPath = toolList.filter(({ path }) =>
          toolPath.includes(path)
        );
        if (matchToolsByPath.length === 1) {
          return matchToolsByPath[0];
        }
        const toolDomain = currentPage.hostname.split(".")[0];
        const matchToolsByDomain = toolList.filter(
          ({ domain }) => domain === toolDomain
        );
        if (matchToolsByDomain.length === 1) {
          return matchToolsByDomain[0];
        }
        return {};
      }
      case "number": {
        const matchTools = toolList.filter(({ ID }) => ID === identification);
        if (matchTools.length === 1) {
          return matchTools[0];
        } else {
          return {};
        }
      }
      case "string": {
        const matchTools = toolList.filter(
          ({ name }) => name === identification
        );
        if (matchTools.length === 1) {
          return matchTools[0];
        } else {
          return {};
        }
      }
      default:
        return {};
    }
  } catch (e) {
    return {};
  }
}
