// VideoToolList
// 引入功能模块
// REACT
import React from "react";
// MUI
import { Box, Grid, Card, CardContent, CardMedia, Typography } from "@mui/material";
import getAllUrlParams from "../../utils/getAllUrlParams";
import { GlobalContext } from "../../utils/globalContext";
// DATA
// PDF工具列表数据包
import { allToolList } from "../../data";
import { Trans } from "react-i18next/icu.macro";
import Link from "./Link";

//工具模块组件
function ToolCardNew({ tool }) {
  // 获取错误页面信息
  const { getLastReferrer } = React.useContext(GlobalContext);
  let [isHome, setIsHome] = React.useState(true);
  React.useEffect(async () => {
    const currentPageUrl = await getLastReferrer();
    const pathArray = currentPageUrl.currentPage.split("/");
    if (currentPageUrl.currentPage === "/" || (pathArray.length === 3 && pathArray[1] === "f")) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, []);
  return (
    <Grid
      item
      container
      xs={12}
      sm={6}
      md={4}
      xl={4}
      sx={{
        "&>a": {
          textDecorationLine: "none",
          width: "100%",
        },
        display:
          /Mobi|Android|iPhone/i.test(navigator.userAgent) === true && tool.ID === 12
            ? "none"
            : "block",
      }}
    >
      <Link href={tool.path} subDomain={tool.domain} position="function_tool_list">
        {/* 工具卡片 */}
        <Card
          sx={{
            border: "2px solid rgba(238, 238, 238,0.4)",
            borderRadius: "10px",
            width: "100%",
            padding: "0px",
            margin: {
              xl: "10px 20px 10px 20px",
              md: "5px 10px",
              sm: "4px 8px",
              xs: "2px 5px",
            },
            boxShadow: "0px 0.2px 10px  rgba(0, 0, 0, 0.03)",
            "&:hover": { transform: "scale(1.01902)" },
            transition: "transform .15s,box-shadow .15s!important",
          }}
          onClick={() => {
            sendConstructEventByEventParams("open_function", {});
            isPC && sendConstructEventByEventParams("open_function_pc", {});
            isHome &&
              sendConstructEventByEventParams("home", {
                click_tools: tool.name,
              });
            !isHome &&
              sendConstructEventByEventParams("select_file_page", {
                click_tools: tool.name,
              });
          }}
        >
          <CardMedia
            sx={{
              height: { sm: "13rem", xs: "15rem" },
            }}
            component={"img"}
            image={"/images/home/" + tool.imgUrl + ".png"}
            alt={tool.imgAlt}
          />
          <CardContent>
            {/* 工具名称 */}
            <Typography
              sx={{
                fontSize: { xl: "24px", md: "20px", sm: "18px", xs: "16px" },
                fontWeight: "700",
                paddingLeft: "10px",
                marginBottom: { xl: "10px", md: "20px", sm: "10px", xs: "0" },
              }}
            >
              <Trans i18nKey={tool.context.name.default}>{tool.context.name.default}</Trans>
            </Typography>
            {/* 工具描述 */}
            <Typography
              sx={{
                fontSize: { xl: "18px", md: "16px", sm: "14px", xs: "14px" },
                overflow: "hidden",
                height: "3rem",
                lineHeight: "1.24rem",
                textOverflow: "ellipsis",
                boxSizing: "content-box",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "3",
                padding: { xl: "13px", md: "10px", sm: "10px", xs: "6px" },
              }}
            >
              <Trans i18nKey={tool.context.description.default}>
                {tool.context.description.default}
              </Trans>
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
}

// MAIN
export default function VideoToolList() {
  let [homeToolList, setHomeToolList] = React.useState([]);
  React.useEffect(() => {
    if (getAllUrlParams(window.location.href).toolListSort !== undefined) {
      let toolListSort = getAllUrlParams(window.location.href).toolListSort;
      // 现对获取的参数进行判断
      toolListSort = decodeURI(toolListSort);
      //  将 中括号里面的内容 拿出来
      toolListSort = toolListSort.slice(toolListSort.indexOf("[") + 1, toolListSort.indexOf("]"));
      console.log("[ toolListSort ] >", toolListSort);
      toolListSort = toolListSort.split(",");
      toolListSort.forEach((item, index) => {
        if (item > 12 || item < 0) {
          log("请输入0~12");
        } else {
          homeToolList[index] = allToolList[item];
          setHomeToolList(homeToolList);
        }
      });
    } else {
      setHomeToolList(allToolList);
    }
  }, []);
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          flexGrow: 1,
          margin: "auto",
          maxWidth: "1200px",
          marginTop: "20px",
        }}
      >
        <Grid container spacing={2}>
          {homeToolList.map((tool) => {
            return <ToolCardNew key={tool.key} tool={tool} />;
          })}
        </Grid>
      </Box>
    </Box>
  );
}
