// 工具类别
export const toolCategory = [
  { ID: 0, name: "Photo Editor" },
  { ID: 1, name: "Converters" },
  { ID: 2, name: "Photo Tools" },
  { ID: 3, name: "More" },
];
// 工具列表
export const toolList = [
  //照片编辑
  {
    functionType: "editor",
    //照片编辑
    ID: 0,
    categoryID: 0,
    key: "1-1-Transition",
    name: "PhotoEditor",
    title: "Photo Editor | Photo - Online Photo Editor",
    meta: "Use photo2tools Free Photo Editor to edit, crop, enhance, or add effects & filters to pictures.  No watermarks.",
    path: "/edit-photo",
    domain: "photoeditor",
    imgUrl: "PhotoEditor",
    imgAlt: "online-editor-photo",
    color: "#7d2ae8",
    icon: "icon_photo_editor.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.img2pdf.name",
        //图片编辑
        default: "Photo Editor",
      },
      description: {
        key: "tool.img2pdf.description",
        default: "Easy photo editing One click easy cropping, resizing, adding frames or text.",
      },
      title: {
        key: "tool.img2pdf.title",
        default: "Photo Editor",
      },
      subtitle: {
        key: "tool.img2pdf.subtitle",
        default: "The best online tool to edit Photo documents.",
      },
      tip: {
        title: {
          key: "tool.img2pdf.tipTitle",
          default: ["How to", "edit", "Photo"],
        },
        body: [
          {
            title: {
              key: "tool.img2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.img2pdf.tip1.text",
              default: "Drag and drop your Photo document into the Photo Editor..",
            },
          },
          {
            title: {
              key: "tool.img2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.img2pdf.tip2.text",
              default:
                "Add text, images, shapes or effects as you wish.You can also edit the size, font, and color of the added content.",
            },
          },
          {
            title: {
              key: "tool.img2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.img2pdf.tip3.text",
              default: "Click download button to save the change photo..",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.img2pdf.successfulTitle",
        default: "Image to PDF Convert Successful",
      },
    },
  },
  //拼图
  {
    ID: 18,
    categoryID: 3,
    key: "2-3-Filters",
    name: "PhotoCollage",
    title: "Classic Collage - Collage | Photo Online Collage Maker",
    meta: "Create online photo collages for free. Add photos without uploading. Arrange them into a free-form collage or use one of the many templates.",
    path: "/collage-photo",
    domain: "photocollage",
    functionType: "collage",
    color: "#7961F2",
    imgUrl: "PhotoCollage",
    imgAlt: "online-collage-photo",
    icon: "icon_photo_collage.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["png"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: false,
    context: {
      name: {
        key: "tool.splitpdf.name",
        //图片拼图
        default: "Photo Collage",
      },
      description: {
        key: "tool.splitpdf.description",
        default: "Combine multiple photos into one.",
      },
      title: {
        key: "tool.splitpdf.title",
        default: "Photo Collage",
      },
      subtitle: {
        key: "tool.splitpdf.subtitle",
        default:
          "Create beautiful photo collages by dropping your images into one of our professionally designed collage templates.",
      },
      tip: {
        title: {
          key: "tool.splitpdf.tipTitle",
          default: ["How to", "Make", "a Photo Collage"],
        },
        body: [
          {
            title: {
              key: "tool.splitpdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.splitpdf.tip1.text",
              default:
                "You can upload several photos at once to the Collage Maker or select from our collection of free stock images.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.splitpdf.tip2.text",
              default:
                "Choose from our collection of professionally designed collage templates or use the Collage Wizard to generate your photo collage automatically.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.splitpdf.tip3.text",
              default:
                "Add text, borders, graphics, patterns, and even more collage cells to enhance the theme of your picture collage.Save your collage layout to BeFunky as a project for future edits, and download it as a print-quality PDF, PNG, or JPEG file.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.compresspdf.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  //photo template
  {
    ID: 29,
    categoryID: 2,
    key: "1-3-Templages",
    path: "/photo-templates",
    name: "PhotoTemplates",
    title: "All Templates - Design | Photo Online Photo Editor",
    meta: "Select the template to create a unique photo",
    imgUrl: "PhotoTemplate",
    imgAlt: "online-edit-template",
    color: "#3D99F5",
    icon: "icon_photo_template.svg",
    context: {
      name: {
        //图片转raw
        default: "Photo templates",
      },
      description: {
        default: "Select the template to create a unique photo",
      },
    },
  },
  //照片裁剪
  {
    ID: 16,
    categoryID: 0,
    key: "0-0-Editor",
    name: "CropPhoto",
    title: "Crop Photo | Photo - Online Photo Editor",
    meta: "Use photo2tools Free Photo Editor to edit, crop, enhance, or add effects & filters to pictures.  No watermarks.",
    path: "/crop-photo",
    domain: "cropphoto",
    functionType: "editor",
    color: "#3D99F5",
    imgUrl: "CropPhoto",
    imgAlt: "online-crop-photo",
    icon: "icon_photo_crop.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2word.name",
        //图片裁剪
        default: "Crop Photo",
      },
      description: {
        key: "tool.pdf2word.description",
        default: "Easily crop any photo and adjust it to the exact size you want.",
      },
      title: {
        key: "tool.pdf2word.title",
        default: "Crop Photo",
      },
      subtitle: {
        key: "tool.pdf2word.subtitle",
        default: "An easy way to crop photo.",
      },
      tip: {
        title: {
          key: "tool.pdf2word.tipTitle",
          default: ["How to", " Crop ", "a Photo"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2word.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2word.tip1.text",
              default:
                "Upload steps: Just click on the button or drag and drop to upload and then you can download the files you need on the download page.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2word.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2word.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2word.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  //照片尺寸调整
  {
    ID: 13,
    categoryID: 4,
    key: "0-0-Resize",
    name: "ResizePhoto", //改（功能名称）
    title: "Resize Photo | Photo - Online Photo Editor",
    meta: "Use photo2tools Free Photo Editor to edit, crop, enhance, or add effects & filters to pictures.  No watermarks.",
    path: "/resize-photo", //改（路由路径）
    domain: "resizephoto", //改（路径）
    functionType: "editor", //改（工具类型）“editor” “compressing” “converting” "collage""
    color: "#3D99F5", //删
    imgUrl: "ResizePhoto",
    imgAlt: "online-resize-photo",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2word.name",
        //图片裁剪
        default: "Resize Photo",
      },
      description: {
        key: "tool.pdf2word.description",
        default:
          "Cropping images has never been easier with the free online photo editor. Easily crop any image and adjust it to the exact size and pixels you want. You don't need any Photoshop skills to edit images.",
      },
      title: {
        key: "tool.pdf2word.title",
        default: "Crop & resize images",
      },
      subtitle: {
        key: "tool.pdf2word.subtitle",
        default: "free image cropper, you can crop images to any size and shape in seconds.",
      },
      tip: {
        title: {
          key: "tool.pdf2word.tipTitle",
          default: ["How to", " Crop ", "a Picture"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2word.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2word.tip1.text",
              default:
                "You can use the free image clipper to quickly clip images online. First, upload the image you want to cut.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2word.tip2.text",
              default:
                "You can freehand crop the picture by dragging the corners of the crop box. To create the perfect picture composition, move the crop box until you're satisfied. ",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2word.tip3.text",
              default:
                "Alternatively, you can choose from preset crop ratios or enter your desired width and height for cropping your picture. Make sure to click the “Keep Aspect Ratio” option if you want to keep the proportion of the original picture.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2word.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  //旋转照片
  {
    ID: 2,
    categoryID: 0,
    key: "1-0-addSubtitles",
    name: "RotatePhoto",
    title: "Rotate Photo | Photo - Online Photo Editor",
    meta: "Use photo2tools Free Photo Editor to edit, crop, enhance, or add effects & filters to pictures.  No watermarks.",
    path: "/rotate-photo",
    domain: "rotatephoto",
    functionType: "editor",
    color: "#3D99F5",
    imgUrl: "RotatePhoto",
    imgAlt: "online-rotate-photo",
    icon: "icon_photo_rotate.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.word2pdf.name",
        //旋转图片
        default: "Rotate Photo",
      },
      description: {
        key: "tool.word2pdf.description",
        default: "Rotate an photo, clockwise or counterclockwise.",
      },
      title: {
        key: "tool.word2pdf.title",
        default: "Rotate Photo",
      },
      subtitle: {
        key: "tool.word2pdf.subtitle",
        default: "Rotate your photo clockwise or counterclockwise。",
      },
      tip: {
        title: {
          key: "tool.word2pdf.tipTitle",
          default: ["How to", "rotate", "Photo"],
        },
        body: [
          {
            title: {
              key: "tool.word2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.word2pdf.tip1.text",
              default:
                "Upload steps: Just click on the button or drag and drop to upload and then you can download the files you need on the download page. ",
            },
          },
          {
            title: {
              key: "tool.word2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.word2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.word2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.word2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.word2pdf.successfulTitle",
        default: "Word to PDF Convert Successful",
      },
    },
  },
  //背景移除
  {
    ID: 3,
    categoryID: 3,
    key: "1-0-Subtitles",
    name: "PhotoBackgroundRemove",
    title: "Remove Background | Photo - Online Photo Editor",
    meta: "Remove photo backgrounds online with just one click. Don't spend hours manually picking pixels. Upload your photo now & see the magic.",
    path: "/remove-background",
    domain: "photobackgroundremove",
    functionType: "ai",
    previewName: "Remove Background",
    aiType: "rmbg",
    color: "#3D99F5",
    imgUrl: "BackgroundRemove",
    imgAlt: "online-remove-background",
    icon: "icon_photo_remove_background.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.word2pdf.name",
        //背景移除
        default: "Remove Background",
      },
      description: {
        key: "tool.word2pdf.description",
        default: "Use Ai to remove the portrait background and edit the photo",
      },
      title: {
        key: "tool.word2pdf.title",
        default: "Remove Photo Background",
      },
      subtitle: {
        key: "tool.word2pdf.subtitle",
        default: "Free & Automatic AI Bg Removal Solution",
      },
      tip: {
        title: {
          key: "tool.word2pdf.tipTitle",
          default: ["How to", "remove", "background from photo?"],
        },
        body: [
          {
            title: {
              key: "tool.word2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.word2pdf.tip1.text",
              default:
                "Click the button ‘Open Image’ to upload image or drag and drop image to start remove background.",
            },
          },
          {
            title: {
              key: "tool.word2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.word2pdf.tip2.text",
              default: "Click to automatically delete the background now.",
            },
          },
          {
            title: {
              key: "tool.word2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.word2pdf.tip3.text",
              default:
                "Download the image in PNG format and keep a transparent background to keep editing.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.word2pdf.successfulTitle",
        default: "Word to PDF Convert Successful",
      },
    },
  },
  //去模糊
  {
    ID: 10,
    categoryID: 3,
    key: "2-2-Audio",
    name: "PhotoEnlarge",
    title: "Remove Blur | Photo - Online Photo Editor",
    meta: "Remove photo blur online with just one click. Upload your photo now & see the magic.",
    path: "/remove-blur",
    domain: "photoenlarge",
    functionType: "ai",
    previewName: "Remove Blur",
    aiType: "deblurring",
    color: "#F23030",
    imgUrl: "RemoveBlur",
    imgAlt: "online-remove-blur",
    icon: "icon_photo_remove_blur.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.compresspdf.name",
        //图片增强器 提高分辨率
        default: "Remove Blur",
      },
      description: {
        key: "tool.compresspdf.description",
        default: "Use Ai to remove photo blur and improve photo resolution.",
      },
      title: {
        key: "tool.compresspdf.title",
        default: "Remove Blur",
      },
      subtitle: {
        key: "tool.compresspdf.subtitle",
        default: "Use Ai to remove photo blur and improve photo resolution.",
      },
      tip: {
        title: {
          key: "tool.compresspdf.tipTitle",
          default: ["How to", "Remove", "Photo Blur"],
        },
        body: [
          {
            title: {
              key: "tool.compresspdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.compresspdf.tip1.text",
              default:
                "Click the ‘Open Image’ button to upload an image or drag and drop an image to start the operation.",
            },
          },
          {
            title: {
              key: "tool.compresspdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.compresspdf.tip2.text",
              default:
                "You can always use the built-in editing tools to optimize your images, including improving resolution.",
            },
          },
          {
            title: {
              key: "tool.compresspdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.compresspdf.tip3.text",
              default:
                "To compare your edited photo to the original, click the “Compare” button at the bottom of the canvas. If you don’t like the way it looks, you can reset your adjustments and revert to your original image.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.compresspdf.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  //照片去雨
  {
    ID: 5,
    categoryID: 3,
    key: "1-1-Photo",
    name: "RemoveRaindrop",
    title: "Remove Raindrop | Photo - Online Photo Editor",
    meta: "Remove photo raindrop online with just one click. Upload your photo now & see the magic.",
    path: "/remove-raindrop",
    domain: "removeraindrop",
    functionType: "ai",
    previewName: "Remove Rain",
    aiType: "deraining",
    icon: "icon_image_to_pdf.svg",
    beforeToolIcon: "icon_img.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#FFB700",
    imgUrl: "RemoveRain",
    imgAlt: "online-remove-raindrop",
    icon: "icon_photo_remove_rain.svg",
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.img2pdf.name",
        //图片去雨
        default: "Remove Raindrop",
      },
      description: {
        key: "tool.img2pdf.description",
        default: "Use Ai to remove raindrops from photos.",
      },
      title: {
        key: "tool.img2pdf.title",
        default: "Picture rain removal",
      },
      subtitle: {
        key: "tool.img2pdf.subtitle",
        default: "Use to remove raindrops. You can completely remove raindrops from photos.",
      },
      tip: {
        title: {
          key: "tool.img2pdf.tipTitle",
          default: ["How to", "Remove", "raindrops from photos?"],
        },
        body: [
          {
            title: {
              key: "tool.img2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.img2pdf.tip1.text",
              default:
                " Click the ‘Open Image’ button to upload an image or drag and drop the image online",
            },
          },
          {
            title: {
              key: "tool.img2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.img2pdf.tip2.text",
              default:
                "You can use the built-in editing tool to optimize the image, and click to remove the rain.",
            },
          },
          {
            title: {
              key: "tool.img2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.img2pdf.tip3.text",
              default:
                "Click the location where you want to remove any raindrops or inappropriate raindrops to get the perfect picture. Preview and save your work in the desired format and quality. By the way, you can share directly on social media.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.img2pdf.successfulTitle",
        default: "Image to PDF Convert Successful",
      },
    },
  },
  //照片去噪
  {
    ID: 2,
    categoryID: 3,
    key: "2-4-Sticker",
    name: "PhotoDenoise",
    title: "Remove Noise | Photo - Online Photo Editor",
    meta: "Remove photo noise online with just one click. Upload your photo now & see the magic.",
    path: "/remove-noise",
    domain: "photodenoise",
    functionType: "ai",
    previewName: "Remove Noise",
    aiType: "denoising",
    color: "#7961F2",
    imgUrl: "RemoveNoise",
    imgAlt: "online-remove-noise",
    icon: "icon_photo_remove_noise.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.mergepdf.name",
        //图片去除噪点
        default: "Remove Noise",
      },
      description: {
        key: "tool.mergepdf.description",
        default: "Use Ai to remove noise from photos.",
      },
      title: {
        key: "tool.mergepdf.title",
        default: "Image noise removal",
      },
      subtitle: {
        key: "tool.mergepdf.subtitle",
        default: "Now, click to remove noise to make your picture clearer",
      },
      tip: {
        title: {
          key: "tool.mergepdf.tipTitle",
          default: ["How to", " Remove ", "image noise"],
        },
        body: [
          {
            title: {
              key: "tool.mergepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.splitpdf.tip1.text",
              default: "Open the photo you wish to edit.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.splitpdf.tip2.text",
              default: "Maximize your picture with noise removal",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.splitpdf.tip3.text",
              default: "Finish modifying and save your work in the format and quality desired.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.mergepdf.successfulTitle",
        default: "Merge PDF Successful",
      },
    },
  },
  //黑白照片上色
  {
    ID: 6,
    categoryID: 3,
    key: "2-3-Text",
    name: "ColorBlackAndWhitePhoto",
    title: "Colorize Photo | Photo - Online Photo Editor",
    meta: "Colorize photo online with just one click. Upload your photo now & see the magic.",
    path: "/colorize-photo",
    domain: "colorblackandwhitephoto",
    functionType: "ai",
    previewName: "Color B&W Photo",
    aiType: "coloring",
    color: "#7961F2",
    imgUrl: "ColorBlackAndWhitePhoto",
    imgAlt: "online-colorize-photo",
    icon: "icon_photo_color_blackandwhite.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.splitpdf.name",
        //黑白照片上色
        default: "Colorize B&W Photo",
      },
      description: {
        key: "tool.splitpdf.description",
        default: "Using Ai to convert black and white photos into color.",
      },
      title: {
        key: "tool.splitpdf.title",
        default: "Color black and white photos",
      },
      subtitle: {
        key: "tool.splitpdf.subtitle",
        default: "Add color to black and white photos!",
      },
      tip: {
        title: {
          key: "tool.splitpdf.tipTitle",
          default: ["How to", " color black", "and white pictures"],
        },
        body: [
          {
            title: {
              key: "tool.splitpdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.splitpdf.tip1.text",
              default:
                "Click the button ‘Apply photo effects now’ and upload the photos you want to edit using.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.splitpdf.tip2.text",
              default:
                "From the Tools menu, select the Picture Effects tool and apply the perfect photo effect to maximize its appeal.",
            },
          },
          {
            title: {
              key: "tool.splitpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.splitpdf.tip3.text",
              default:
                "Adjust the color and effect intensity for maximum results,Save your work, choosing the format and size you wish.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.compresspdf.successfulTitle",
        default: "Compress PDF Successful",
      },
    },
  },
  //照片卡通化
  {
    ID: 7,
    categoryID: 3,
    key: "1-3-Text",
    name: "PhotoToCartoon",
    title: "Photo To Cartoon | Photo - Online Photo Editor",
    meta: "Transform your photo into a cartoon with a single click!",
    path: "/photo-to-cartoon",
    domain: "phototocartoon",
    functionType: "ai",
    previewName: "Photo To Cartoon",
    aiType: "cartoon",
    color: "#FFB700",
    imgUrl: "PhotoToCartoon",
    imgAlt: "photo-to-cartoon",
    icon: "icon_photo_to_cartoon.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片卡通化
        default: "Photo To Cartoon",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Use Ai to turn your photo into a cartoon.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Make Cartoon Photo",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert Photo to Cartoon in Seconds ",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "Make ", "Cartoon Photo"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the ‘Cartoonize Your Photo’ on this page and jump to Cartoon Converter.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default:
                "Click ‘Open Image’ to upload the image you want to cartoonize. You can add anything from portrait and landscape to pet images.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default:
                "Slide the intensity slider to change the filter effect until making the photo to cartoon you are satisfied.Once you've finished, click the Download button to save. Turning pictures to cartoons is so easy with Fotor!",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //照片动漫化
  {
    ID: 8,
    categoryID: 4,
    key: "2-1-Flip",
    name: "PhotoToAnime",
    path: "/photo-to-anime",
    domain: "phototoanime",
    functionType: "ai",
    aiType: "rmbg",
    color: "#0FC0C5",
    imgUrl: "PhotoToAnime",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdfreader.name",
        //图片动漫化
        default: "Photo To Anime",
      },
      description: {
        key: "tool.pdfreader.description",
        default:
          "100% automatically animates your photos. Just apply the anime filter to your pictures, and the anime photo editor can help you get interesting and creative photos with one click.",
      },
      title: {
        key: "tool.pdfreader.title",
        default: "Animation of pictures",
      },
      subtitle: {
        key: "tool.pdfreader.subtitle",
        default: "Convert photos to anime in seconds",
      },
      tip: {
        title: {
          key: "tool.pdfreader.tipTitle",
          default: ["How to", "Make ", "Anime picture"],
        },
        body: [
          {
            title: {
              key: "tool.pdfreader.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdfreader.tip1.text",
              default: "Click ‘Animate your photos’ on this page and jump to Animation Converter.",
            },
          },
          {
            title: {
              key: "tool.pdfreader.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdfreader.tip2.text",
              default:
                " Click Open Image to upload the image to be animated. You can add anything from portraits and landscapes to pet images.",
            },
          },
          {
            title: {
              key: "tool.pdfreader.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdfreader.tip3.text",
              default:
                "Slide the Strength slider to change the filter effect until you make your photo into a satisfactory animation.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdfreader.successfulTitle",
        default: "PDF Reader",
      },
    },
  },
  //将贴纸添加到照片
  {
    ID: 9,
    categoryID: 2,
    key: "1-2-Crop",
    name: "AddStickerToPhoto",
    title: "Add Stickers To Photo | Photo - Online Photo Editor",
    meta: "Use photo2tools Free Photo Editor to edit, crop, enhance, or add effects & filters to pictures.  No watermarks.",
    path: "/stickers",
    domain: "addstickertophoto",
    functionType: "editor",
    color: "#FF8000",
    imgUrl: "AddElements",
    imgAlt: "add-sticker-to-photo",
    icon: "icon_photo_elements.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.ppt2pdf.name",
        default: "Add Elements To Photo",
      },
      description: {
        key: "tool.ppt2pdf.description",
        default: "Add some elements to your photo, shapes or emojis.",
      },
      title: {
        key: "tool.ppt2pdf.title",
        default: "Add Elements To Photo",
      },
      subtitle: {
        key: "tool.ppt2pdf.subtitle",
        default: "A variety of elements are available.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "add elements", "to a Photo"],
        },
        body: [
          {
            title: {
              key: "tool.ppt2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.ppt2pdf.tip1.text",
              default:
                "Upload steps: Just click on the button or drag and drop to upload and then you can download the files you need on the download page. ",
            },
          },
          {
            title: {
              key: "tool.ppt2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.ppt2pdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.ppt2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.ppt2pdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.ppt2pdf.successfulTitle",
        default: "PPT to PDF Convert Successful",
      },
    },
  },
  //添加文本到照片
  {
    ID: 4,
    categoryID: 2,
    key: "2-6-Resize",
    name: "AddTextToPhoto",
    title: "Add Texts To Photo | Photo - Online Photo Editor",
    meta: "Use photo2tools Free Photo Editor to edit, crop, enhance, or add effects & filters to pictures.  No watermarks.",
    path: "/texts",
    domain: "addtexttophoto",
    functionType: "editor",
    color: "#0FC0C5",
    imgUrl: "AddText",
    imgAlt: "add-text-to-photo",
    icon: "icon_photo_text.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.deletepdf.name",
        //图片添加文本
        default: "Add Text To Photo",
      },
      description: {
        key: "tool.deletepdf.description",
        default: "Add text to your photos and edit them.",
      },
      title: {
        key: "tool.deletepdf.title",
        default: "Add Text To Photo",
      },
      subtitle: {
        key: "tool.deletepdf.subtitle",
        default: "You can use the text editor to make cool photo.",
      },
      tip: {
        title: {
          key: "tool.deletepdf.tipTitle",
          default: ["How to", "add text", "to a Photo"],
        },
        body: [
          {
            title: {
              key: "tool.deletepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.deletepdf.tip1.text",
              default:
                "Upload steps: Just click on the button or drag and drop to upload and then you can download the files you need on the download page. ",
            },
          },
          {
            title: {
              key: "tool.deletepdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.deletepdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.deletepdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.deletepdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.deletepdf.successfulTitle",
        default: "Delete PDF Pages Successful",
      },
    },
  },
  //照片绘画
  {
    ID: 11,
    categoryID: 2,
    key: "2-5-Noise",
    name: "DrawPhoto",
    title: "Draw Photo | Photo - Online Photo Editor",
    meta: "Use photo2tools Free Photo Editor to edit, crop, enhance, or add effects & filters to pictures.  No watermarks.",
    path: "/draw",
    domain: "photodraw",
    functionType: "editor",
    color: "#0FC0C5",
    imgUrl: "PhotoDraw",
    imgAlt: "draw-photo",
    icon: "icon_photo_draw.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.rotatepdf.name",
        //图片绘画
        default: "Photo Draw",
      },
      description: {
        key: "tool.rotatepdf.description",
        default: "With different brushes sizes and colors, bring your photo out of others.",
      },
      title: {
        key: "tool.rotatepdf.title",
        default: "Photo Draw",
      },
      subtitle: {
        key: "tool.rotatepdf.subtitle",
        default: " An easy way to draw on photo.",
      },
      tip: {
        title: {
          key: "tool.rotatepdf.tipTitle",
          default: ["How to", "draw on", "a Photo"],
        },
        body: [
          {
            title: {
              key: "tool.rotatepdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.rotatepdf.tip1.text",
              default:
                "Upload steps: Just click on the button or drag and drop to upload and then you can download the files you need on the download page. ",
            },
          },
          {
            title: {
              key: "tool.rotatepdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.rotatepdf.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.rotatepdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.rotatepdf.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.rotatepdf.successfulTitle",
        default: "Rotate PDF Successful",
      },
    },
  },
  //照片添加相框
  {
    ID: 12,
    categoryID: 2,
    key: "0-1-Rotate",
    name: "AddFrameToPhoto",
    title: "Add Frame To Photo | Photo - Online Photo Editor",
    meta: "Use photo2tools Free Photo Editor to edit, crop, enhance, or add effects & filters to pictures.  No watermarks.",
    path: "/frame",
    domain: "addframetophoto",
    functionType: "editor",
    color: "#FFB700",
    imgUrl: "AddFrame",
    imgAlt: "add-frame-to-photo",
    icon: "icon_photo_frame.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2img.name",
        //图片添加相框
        default: "Add Frame To Photo",
      },
      description: {
        key: "tool.pdf2img.description",
        default:
          "Simply click on whichever frame you like in the Frames tab to preview it on your photo.",
      },
      title: {
        key: "tool.pdf2img.title",
        default: "Add Frame To Photo",
      },
      subtitle: {
        key: "tool.pdf2img.subtitle",
        default: "Add a frame to your photo to make it look more solemn",
      },
      tip: {
        title: {
          key: "tool.pdf2img.tipTitle",
          default: ["How to", "add a frame", " to a Photo"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2img.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2img.tip1.text",
              default:
                "Upload steps: Just click on the button or drag and drop to upload and then you can download the files you need on the download page. ",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2img.tip2.text",
              default:
                "Device support: you can choose to use it on ipad pc mobile phone, perfect for popular browsers.",
            },
          },
          {
            title: {
              key: "tool.pdf2img.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2img.tip3.text",
              default:
                "Security: Our server will automatically delete the downloaded file after you store it for a period of time to protect your privacy.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2img.successfulTitle",
        default: "PDF to JPG Convert Successful",
      },
    },
  },
  //图片压缩
  {
    ID: 1,
    categoryID: 3,
    key: "2-0-Compress",
    name: "PhotoCompress",
    title: "Compress Photo Online",
    meta: "Free online image compression. Make image files smaller to send them via e-mail or whatsapp or upload them to the internet.",
    path: "/compress-photo",
    domain: "photocompress",
    functionType: "compress",
    imgUrl: "PhotoCompress",
    imgAlt: "online-compress-photo",
    color: "#7d2ae8",
    icon: "icon_photo_compress.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.editorpdf.name",
        //图片压缩
        default: "Compress Photo",
      },
      description: {
        key: "tool.editorpdf.description",
        default: "An photo compressor can reduce the file size of an photo in different ways.",
      },
      title: {
        key: "tool.editorpdf.title",
        default: "Compress Photo",
      },
      subtitle: {
        key: "tool.editorpdf.subtitle",
        default: "Reduce the size of your Photo online.",
      },
      tip: {
        title: {
          key: "tool.editorpdf.tipTitle",
          default: ["How to", " compress ", " Photo"],
        },
        body: [
          {
            title: {
              key: "tool.editorpdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.editorpdf.tip1.text",
              default: " Select the photo you want to compress..",
            },
          },
          {
            title: {
              key: "tool.editorpdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.editorpdf.tip2.text",
              default:
                "Choose a compression preset from the dropdown menu. It will determine the quality of your image.",
            },
          },
          {
            title: {
              key: "tool.editorpdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.editorpdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.editorpdf.successfulTitle",
        default: "PDF Edit Successful",
      },
    },
  },
  //年龄修改
  {
    ID: 14,
    categoryID: 4,
    key: "0-0-Cutter",
    name: "ChangeFaceAge",
    path: "/VideoCutter",
    domain: "videocutter",
    functionType: "editor",
    color: "#3D99F5",
    imgUrl: "ChangeFaceAge",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: ["more", 1, 0, 6, , 8, 10, 9, 12],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.pdf2word.name",
        //图片人物年龄修改
        default: "Change Face Age",
      },
      description: {
        key: "tool.pdf2word.description",
        default:
          "Provide perfect portrait image for each occasion. The free photo editor supports modifying your age, making you younger and saving your wonderful photos.",
      },
      title: {
        key: "tool.pdf2word.title",
        default: "Cartonization of pictures",
      },
      subtitle: {
        key: "tool.pdf2word.subtitle",
        default: "Modifying your age can make you younger.",
      },
      tip: {
        title: {
          key: "tool.pdf2word.tipTitle",
          default: ["How to", "modify the age of", "a person in a picture"],
        },
        body: [
          {
            title: {
              key: "tool.pdf2word.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.pdf2word.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.pdf2word.tip2.text",
              default:
                "You can use the built-in editing tools to modify the image, including the age modification function.",
            },
          },
          {
            title: {
              key: "tool.pdf2word.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.pdf2word.tip3.text",
              default:
                "To compare the edited photo with the original photo, click the Compare button at the bottom of the canvas. If you don't like the adjusted effect, you can reset your adjustment and restore to your original image.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.pdf2word.successfulTitle",
        default: "PDF to Word Convert Successful",
      },
    },
  },
  // 照片性别转换
  {
    ID: 15,
    categoryID: 4,
    key: "1-2-Overlay",
    functionType: "editor",
    color: "#FF8000",
    name: "ChangeFaceGender",
    path: "/ChangeFaceGender",
    domain: "changegacegender",
    imgUrl: "ConvertGender",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: null,
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.ppt2pdf.name",
        //图片人物性别转换
        default: "Convert Gender",
      },
      description: {
        key: "tool.ppt2pdf.description",
        default:
          "Provide perfect portrait image for each occasion. The free photo editor also has the function of gender conversion, and saves your wonderful photos. ",
      },
      title: {
        key: "tool.ppt2pdf.title",
        default: "Gender conversion of picture characters",
      },
      subtitle: {
        key: "tool.ppt2pdf.subtitle",
        default:
          "It provides perfect portrait images for each occasion and the function of gender conversion.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "use the function ", "of gender conversion of picture characters."],
        },
        body: [
          {
            title: {
              key: "tool.ppt2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.ppt2pdf.tip1.text",
              default:
                "Click the Open Image button to upload an image or drag and drop an image to begin editing your picture.",
            },
          },
          {
            title: {
              key: "tool.ppt2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.ppt2pdf.tip2.text",
              default:
                "You can use the built-in editing tools to optimize the image, including the gender conversion function of the characters, to see different yourself.",
            },
          },
          {
            title: {
              key: "tool.ppt2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.ppt2pdf.tip3.text",
              default:
                "To compare the edited photo with the original photo, click the Compare button at the bottom of the canvas. If you don't like the effect, you can reset your adjustments and restore to your original image.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.ppt2pdf.successfulTitle",
        default: "PPT to PDF Convert Successful",
      },
    },
  },
  //photo to bmp
  {
    ID: 19,
    categoryID: 1,
    key: "1-3-Converter",
    name: "PhotoToBmp",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-bmp",
    domain: "phototobmp",
    functionType: "convert",
    icon: "icon_photo_bmp.svg",
    beforeToolIcon: "icon_excel_new.svg",
    arrow: "icon_arrow.svg",
    afterToolIcon: "icon_pdf_new.svg",
    color: "#FFB700",
    imgUrl: "PhotoToBmp",
    imgAlt: "online-convert-photo",
    icon: "icon_photo_to_bmp.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["bmp"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转raw
        default: "Photo to bmp",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to bmp",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to bmp",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to bmp.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to bmp"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin convert.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to jpg
  {
    ID: 20,
    categoryID: 1,
    key: "1-3-Jpg",
    name: "PhotoToJpg",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-jpg",
    domain: "phototojpg",
    functionType: "convert",
    imgUrl: "PhotoToJpg",
    imgAlt: "online-convert-photo",
    color: "#0FC0C5",
    icon: "icon_photo_to_jpg.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["jpg"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转jpg
        default: "Photo to jpg",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to jpg.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to jpg",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to jpg.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to jpg"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin convert.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to png
  {
    ID: 21,
    categoryID: 1,
    key: "1-3-Png",
    name: "PhotoToPng",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-png",
    domain: "phototopng",
    functionType: "convert",
    imgUrl: "PhotoToPng",
    imgAlt: "online-convert-photo",
    color: "#0FC0C5",
    icon: "icon_photo_to_png.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["png"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转png
        default: "Photo to png",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to png.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to png",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to png",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to png"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to tif
  {
    ID: 22,
    categoryID: 1,
    key: "1-3-Tif",
    name: "PhotoToTif",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-tif",
    domain: "photototif",
    functionType: "convert",
    imgUrl: "PhotoToTif",
    imgAlt: "online-convert-photo",
    color: "#7d2ae8",
    icon: "icon_photo_to_tif.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["tif"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转tif
        default: "Photo to tif",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to tif.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to tif",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to tif.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to tif"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to tga
  {
    ID: 23,
    categoryID: 1,
    key: "1-3-Tga",
    name: "PhotoToTga",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-tga",
    domain: "phototga",
    functionType: "convert",
    imgUrl: "PhotoToTga",
    imgAlt: "online-convert-photo",
    color: "#7d2ae8",
    icon: "icon_photo_to_tga.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["tga"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转tga
        default: "Photo to tga",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to tga.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to tga",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to tga.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to tga"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to pcx
  {
    ID: 24,
    categoryID: 1,
    key: "1-3-Pcx",
    name: "PhotoToPcx",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-pcx",
    domain: "phototopcx",
    functionType: "convert",
    imgUrl: "PhotoToPcx",
    imgAlt: "online-convert-photo",
    color: "#3D99F5",
    icon: "icon_photo_to_pcx.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["pcx"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转pcx
        default: "Photo to pcx",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to pcx.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to pcx",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to pcx.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to pcx"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to webp
  {
    ID: 25,
    categoryID: 1,
    key: "1-3-Webp",
    name: "PhotoToWebp",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-webp",
    domain: "phototowebp",
    functionType: "convert",
    imgUrl: "PhotoToWebp",
    imgAlt: "online-convert-photo",
    color: "#FF8000",
    icon: "icon_photo_to_webp.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["webp"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转webp
        default: "Photo to webp",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to webp.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to webp",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to webp.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to webp"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to avif
  {
    ID: 26,
    categoryID: 1,
    key: "1-3-Avif",
    name: "PhotoToAvif",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-avif",
    domain: "phototoavif",
    functionType: "convert",
    imgUrl: "PhotoToAvif",
    imgAlt: "online-convert-photo",
    color: "#7d2ae8",
    icon: "icon_photo_to_avif.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["avif"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转avif
        default: "Photo to avif",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to avif.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to avif",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to avif.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to avif"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to WMF
  {
    ID: 27,
    categoryID: 1,
    key: "1-3-WMF",
    name: "PhotoToWMF",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-WMF",
    domain: "phototowmf",
    functionType: "convert",
    color: "#FF8000",
    imgUrl: "PhotoToWMF",
    imgAlt: "online-convert-photo",
    icon: "icon_photo_to_wmf.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["WMF"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转WMF
        default: "Photo to WMF",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to WMF.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to WMF",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to WMF.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to WMF"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
  //photo to raw
  {
    ID: 28,
    categoryID: 1,
    key: "1-3-Raw",
    name: "PhotoToRaw",
    title: "Image Converter | photo2tools",
    meta: "Photo Converter - PNG, JPEG and many more supported",
    path: "/photo-to-raw",
    domain: "phototoraw",
    functionType: "convert",
    imgUrl: "PhotoToRaw",
    imgAlt: "online-convert-photo",
    color: "#3D99F5",
    icon: "icon_photo_to_raw.svg",
    inputFileType: [
      "jpeg",
      "bmp",
      "jpg",
      "png",
      "tif",
      "tga",
      "pcx",
      "webp",
      "avif",
      "WMF",
      "raw",
      "svg",
    ],
    outputFileType: ["raw"],
    recommendFunction: [0, 2, 6, 8, 10, 9, , 12, 11],
    isAutoFunction: true,
    context: {
      name: {
        key: "tool.excel2pdf.name",
        //图片转raw
        default: "Photo to raw",
      },
      description: {
        key: "tool.excel2pdf.description",
        default: "Convert photo to raw.",
      },
      title: {
        key: "tool.excel2pdf.title",
        default: "Photo to raw",
      },
      subtitle: {
        key: "tool.excel2pdf.subtitle",
        default: "Convert photo to raw.",
      },
      tip: {
        title: {
          key: "tool.ppt2pdf.tipTitle",
          default: ["How to", "convert ", "photo format to WMF"],
        },
        body: [
          {
            title: {
              key: "tool.excel2pdf.tip1.title",
              default: "Tip1",
              color: "#D9413D",
            },
            text: {
              key: "tool.excel2pdf.tip1.text",
              default:
                "Click the Open Photo button to upload an photo or drag and drop an photo to begin editing.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip2.title",
              default: "Tip2",
              color: "#041699",
            },
            text: {
              key: "tool.excel2pdf.tip2.text",
              default: "Select the photo format you want to convert from the drop-down menu.",
            },
          },
          {
            title: {
              key: "tool.excel2pdf.tip3.title",
              default: "Tip3",
              color: "#C2AE18",
            },
            text: {
              key: "tool.excel2pdf.tip3.text",
              default: "Click download button to save the change photo.",
            },
          },
        ],
      },
      successfulTitle: {
        key: "tool.excel2pdf.successfulTitle",
        default: "Excel to PDF Convert Successful",
      },
    },
  },
];
//
export const extraTool = [
  {
    key: "ex-0-moretool",
    name: "MoreTool",
    path: "/",
    domain: "",
    icon: "icon_more_tool.svg",
    context: {
      name: {
        key: "tool.moretool.name",
        default: "More tools",
      },
    },
  },
];
// 模板列表
export const templates = [
  {
    toolName: "Legal Contract",
    path: "/LegalContract",
    EventCategroy: "LegalContract",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "LegalContract.svg",
    fill: "#FC9A9A",
  },
  {
    toolName: "Chart Vector",
    path: "/ChartVector",
    EventCategroy: "ChartVector",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "ChartVector.svg",
    fill: "#FFCB00",
  },
  {
    toolName: "PDF/A Document",
    path: "/PDFADocument",
    EventCategroy: "PDFADocument",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "PDFADocument.svg",
    fill: "#82D8FF",
  },
  {
    toolName: "PDF/A Document",
    path: "/PDFBDocument",
    EventCategroy: "PDFBDocument",
    domain: "templates",
    isNew: false,
    isImage: false,
    selectDescribe: "Upload steps: Just click on the PDF or drag and drop to upload and then ",
    beforeToolIcon: "PDFBDocument.svg",
    fill: "#A25DDC",
  },
];
export default { toolCategory, toolList, templates, extraTool };
// 工具ID
// 0  pdf2word
// 1  pdf2img
// 2  word2pdf
// 3  img2pdf
// 4  ppt2pdf
// 5  excel2pdf
// 6  editorpdf
// 7  pdfreader
// 8  compresspdf
// 9  splitpdf
// 10 mergepdf
// 11 rotatepdf
// 12 deletepdf
