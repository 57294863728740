import { Box, Grid, List, ListItemButton, ListItemText, ListSubheader } from "@mui/material";
import { Trans } from "react-i18next/icu.macro";
import Link from "./Link";
import { toolLists } from "../../data";
function MenuListItem({ tool }) {
  return (
    <Link
      href={tool.path}
      subDomain={tool.domain}
      position="menu_to_function"
      style={{ textDecoration: "none", color: "#000" }}
    >
      <ListItemButton
        className="sds"
        sx={{
          borderRadius: "8px",
          "&:hover": { backgroundColor: tool.color, color: "#fff" },
          marginLeft: "-15px",
        }}
        onClick={() => {
          sendConstructEventByEventParams("open_function", {});
          isPC && sendConstructEventByEventParams("open_function_pc", {});
          sendConstructEventByEventParams("navbar_drop_menu", {
            click: "navigation",
            navigation: tool.name,
          });
        }}
      >
        <Box sx={{ width: 24, height: 24, marginRight: "5px" }}>
          <img
            style={{ width: "24px", height: "24px" }}
            src={"/images/icon/" + tool.icon}
            alt={tool.imgAlt}
          />
        </Box>
        <ListItemText
          primaryTypographyProps={{
            sx: {
              fontSize: "14px",
            },
          }}
          primary={<Trans i18nKey={tool.context.name.default}>{tool.context.name.default}</Trans>}
        />
        {/* {tool.isNew === true && (
          <Chip
            label="全新"
            sx={{
              height: "20px",
              lineHeight: "20px",
              color: "#fff",
              fontSize: "12px",
              backgroundColor: "#000",
              borderRadius: "10px",
              textAlign: "center",
            }}
          />
        )} */}
      </ListItemButton>
    </Link>
  );
}
function MenuList({ toolList, index }) {
  return (
    <Grid sx={{ padding: "0px" }} item xs={30} sm={15} md={10} lg={6}>
      <Box sx={{ width: 1 }}>
        <List
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              sx={{
                paddingLeft: 0,
              }}
            >
              {toolList.category}
            </ListSubheader>
          }
        >
          {toolList.toolList.map((tool) => (
            <MenuListItem key={tool.key} tool={tool} />
          ))}
        </List>
      </Box>
    </Grid>
  );
}
export default function MenuLists() {
  return (
    <Grid container columns={30} sx={{ maxWidth: "1440px", margin: "auto", padding: "0 24px" }}>
      {toolLists.map((toolList, index) => (
        <MenuList key={toolList.category} toolList={toolList} index={index} />
      ))}
    </Grid>
  );
}
