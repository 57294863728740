import {
  getSuggestToolList,
  getAllToolList,
  getEditorToolList,
  getConvertToolList,
  getToolListDistinguishByCategory,
  getRecommendFunctionList,
  getURLChannelIgnoreList,
  getNavOptionsList,
  getToolList,
} from "./getToolList";
import { getTool } from "./getToolInfo";
const SUGGEST_TOOL_LIST = process.env.REACT_APP_SUGGEST_TOOL_LIST?.split(
  ","
) || [0, 2, 8, 3, 1, 7];
const NAV_TOOL_LIST = process.env.REACT_APP_SUGGEST_TOOL_LIST?.split(",") || [
  0, 1, 4,
];
export const suggestToolList = getToolList(SUGGEST_TOOL_LIST);
export const allToolList = getAllToolList();
export const convertToolList = getConvertToolList();
export const editorToolList = getEditorToolList();
export const toolLists = getToolListDistinguishByCategory();
export const urlChannelIgnoreList = getURLChannelIgnoreList();
export const navOptionsList = getToolList(NAV_TOOL_LIST);
export { getRecommendFunctionList, getTool };
