// 跨域存储配置
import storage from "../storage";
export default async function getLanguage() {
  await storage.onConnect();
  const value = await storage.get("user_language");
  return {
    name: "crossStorageDetector",
    lookup(options) {
      return value;
    },
    cacheUserLanguage(lng, options) {},
  };
}
