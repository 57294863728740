import { toolList, toolCategory, extraTool } from "./tools";
const SUGGEST_TOOL_LIST = process.env.REACT_APP_SUGGEST_TOOL_LIST?.split(",") || [0, 2, 8, 3, 1, 7];
const NAV_TOOL_LIST = process.env.REACT_APP_SUGGEST_TOOL_LIST?.split(",") || [2, 8, 10, 6];

// 分类获取工具列表
/**
 * 获取推荐功能
 * @returns {Array} suggestToolList
 */
export function getSuggestToolList() {
  try {
    const suggestToolList = SUGGEST_TOOL_LIST.map((suggestToolID) => toolList[suggestToolID]);
    // logi("suggestToolList:", suggestToolList);
    return suggestToolList;
  } catch (e) {
    // console.log(e);
    return [];
  }
}
/**
 * 单独获取Convert类别工具列表
 * @returns {Array} toolList 工具列表
 */
export function getConvertToolList() {
  try {
    const convertToolList = toolList.filter(
      ({ categoryID }) => categoryID === 0 || categoryID === 1,
    );

    return convertToolList;
  } catch (e) {
    // loge(e);
    return [];
  }
}
/**
 * 获取所有工具的信息
 * @returns {Array} toolList 工具列表
 */
export function getAllToolList() {
  try {
    const convertToolList = toolList.filter(
      ({ categoryID }) =>
        categoryID === 0 || categoryID === 1 || categoryID === 2 || categoryID === 3,
    );

    return convertToolList;
  } catch (e) {
    // loge(e);
    return [];
  }
}
/**
 * 单独获取Editor类别工具列表
 * @returns {Array} toolList 工具列表
 */
export function getEditorToolList() {
  try {
    return toolList.filter(({ categoryID }) => categoryID === 2);
  } catch (e) {
    // loge(e);
    return [];
  }
}
/**
 * 获取通过类别区分的工具列表
 * @returns {Array} toolListDistinguishByCategory
 */
export function getToolListDistinguishByCategory() {
  try {
    return toolCategory.map(({ ID, name }) => {
      return {
        category: name,
        open: false,
        toolList: toolList.filter(({ categoryID }) => ID === categoryID),
      };
    });
  } catch (e) {
    // loge(e);
    return [];
  }
}
/**
 * 获取推荐功能列表
 * @param {number} mark 需要获取推荐功能列表的功能标识 ID
 * @param {String} mark 需要获取推荐功能列表的功能标识 name
 * @returns {Array} recommendFunctionList 推荐功能列表
 */
export function getRecommendFunctionList(mark) {
  try {
    switch (typeof mark) {
      case "number": {
        const [tool] = toolList.filter(({ ID }) => mark === ID);
        return tool.recommendFunction.map((ID) =>
          typeof ID === "number" ? toolList[ID] : moreTool,
        );
      }
      case "string": {
        const [tool] = toolList.filter(({ name, domain }) => mark === name || mark === domain);
        return tool.recommendFunction.map((ID) =>
          typeof ID === "number" ? toolList[ID] : extraTool[0],
        );
      }
      default: {
        loge("Unknown function mark", mark);
        return [];
      }
    }
  } catch (e) {
    // loge(e);
    return [];
  }
}
/**
 * 获取 URLChannel 忽略列表
 * @returns {Array} urlChannelIgnoreList
 */
export function getURLChannelIgnoreList() {
  try {
    return toolList.map(({ path }) => path);
  } catch (e) {
    return [];
  }
}
/**
 * 获取头部导航选项工具列表
 * @returns {Array} navOptionsList
 */
export function getNavOptionsList() {
  try {
    return NAV_TOOL_LIST.map((ID) => toolList[ID]);
  } catch (e) {
    return [];
  }
}

/**
 * 获取指定(顺序)的工具列表
 * @param {Array} sortTools 工具顺序列表
 * @param {String} sortTools[*] 工具标识 ID name domain
 * @returns {Array} toolList 工具列表
 */
export function getToolList(sortTools) {
  try {
    if (sortTools) {
      return sortTools.map((mark) =>
        toolList.find((tool) => tool.ID === mark || tool.name === mark || tool.domain === mark),
      );
    } else {
      return toolList;
    }
  } catch (e) {
    return [];
  }
}
