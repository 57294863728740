import chalk from "chalk";
/**
 *
 * @param type 输入的颜色
 * @param data 打印的数据
 */
window.log = function (type, ...data) {
  // 控制是否开始console
  const canProductionConsole = window.localStorage.getItem("canConsole");
  if (process.env.NODE_ENV == "development" || canProductionConsole == "true") {
    switch (type) {
      case "blue":
        console.debug(chalk.whiteBright.bgBlue(...data));
        break;
      case "green":
        console.info(chalk.whiteBright.bgGreen(...data));
        break;
      case "red":
        console.error(chalk.whiteBright.bgRed(...data));
        break;
      case "yellow":
        console.warn(chalk.whiteBright.bgYellow(...data));
        break;
      default:
        console.log(type, ...data);
        break;
    }
  } else {
    return;
  }
};

window.logi = function (...data) {
  window.log("green", ...data);
};
window.logd = function (...data) {
  window.log("blue", ...data);
};
window.logw = function (...data) {
  window.log("yellow", ...data);
};
window.loge = function (...data) {
  window.log("red", ...data);
};
window.logg = function (...data) {
  window.log("", ...data);
};
