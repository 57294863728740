import api, { response } from ".";
import { subscribe } from "./url";
/**
 * 同步服务器端订阅计划
 * @param {Function} successCallback 请求成功时回调
 * @param {Function} failureCallback 请求失败时回调
 * @returns {*} response 请求响应结果
 * @returns {Object} response.response.monthlyPlan 月订阅计划
 * @returns {Object} response.response.yearlyPlan 年订阅计划
 * @returns {String} response.response.clientId 客户ID
 */
export async function syncServerSubscriptionPlan(successCallback = "", failureCallback = "") {
  try {
    const res = await api({
      url: subscribe.syncSubPlanUrl,
      method: "get",
    }).catch((e) => e);
    if (res.data && res.data.status) {
      if (res.data.status === 2000) {
        const plan = {
          monthlyPlan: res.data.data.monthlyPlan,
          yearlyPlan: res.data.data.yearlyPlan,
          clientId: res.data.client_id,
        };
        return response(
          {
            status: "success",
            message: "Sync server side subscription plan successfully.",
            response: plan,
          },
          successCallback,
        );
      } else {
        return response(
          {
            status: "error",
            message: "Sync server side subscription plan failed.",
          },
          failureCallback,
        );
      }
    } else {
      return response({ status: "error", message: "Network error." }, failureCallback);
    }
  } catch (e) {
    return response({ status: "error", message: "Unknown error." }, failureCallback);
  }
}
/**
 * 同步用户订阅状态
 * @param {Object} param 同步用户订阅状态请求数据
 * @param {String} param.userToken 用户登录凭证
 * @param {Function} successCallback 请求成功时回调
 * @param {Function} failureCallback 请求失败时回调
 * @returns {*} response 请求响应结果
 * @returns {Object} response.response 用户订阅状态
 */
export async function syncUserSubscriptionState(
  { userToken },
  successCallback = "",
  failureCallback = "",
) {
  try {
    if (userToken) {
      const res = await api({
        url: subscribe.syncUserSubStateUrl,
        method: "get",
        headers: { Authorization: userToken },
      });
      if (res.data && res.data.status) {
        if (res.data.status === 2000) {
          return response(
            {
              status: "success",
              message: "Sync user subscription status successfully.",
              response: res.data.data,
            },
            successCallback,
          );
        } else {
          return response(
            {
              status: "error",
              message: "Sync user subscription status failed.",
            },
            failureCallback,
          );
        }
      } else {
        return response({ status: "error", message: "Network error." }, failureCallback);
      }
    } else {
      return response(
        {
          status: "error",
          message: "userToken was required.",
        },
        failureCallback,
      );
    }
  } catch (e) {
    return response({ status: "error", message: "Unknown error." }, failureCallback);
  }
}
/**
 * 验证用户订阅有效性
 * @param {Object} param 验证用户订阅有效性请求参数
 * @param {String} param.userName 用户名,即用户邮箱
 * @param {String} param.userToken 用户登录凭证
 * @param {String} param.planId 用户订阅计划ID
 * @param {String} param.subscriptionId 用户订阅订单ID
 * @param {*} successCallback 请求成功时回调
 * @param {*} failureCallback 请求失败时回调
 * @returns {*} response 请求响应结果
 */
export async function verifySubscriptionValidity(
  { userName, userToken, planId, subscriptionId },
  successCallback = "",
  failureCallback = "",
) {
  try {
    if (userName && userToken && planId && subscriptionId) {
      const res = await api({
        url: subscribe.verifyUserSubIdUrl,
        method: "post",
        data: { userName, planId, subscriptionId },
        headers: {
          Authorization: userToken,
        },
      }).catch((e) => e);
      if (res.data && res.data.status) {
        if (res.data.status === 3000) {
          const sync = await syncUserSubscriptionState({ userToken });
          if (sync.status === "success" && sync.response) {
            // 同步订阅状态成功
            return response(
              {
                status: "success",
                message: "Sync user subscription successfully.",
                response: sync.response,
              },
              successCallback,
            );
          } else {
            // 同步订阅状态失败
            return response(
              {
                status: "error",
                message: sync.message,
              },
              failureCallback,
            );
          }
        } else {
          return response(
            {
              status: "error",
              message: "Sync user subscription status failed.",
            },
            failureCallback,
          );
        }
      } else {
        return response({ status: "error", message: "Network error." }, failureCallback);
      }
    } else {
      return response(
        {
          status: "error",
          message: " userName, userToken, planId and subscriptionId was required.",
        },
        failureCallback,
      );
    }
  } catch (e) {
    return response({ status: "error", message: "Unknown error." }, failureCallback);
  }
}
/**
 * 暂停用户订阅
 * @param {Object} param 暂停用户订阅请求数据
 * @param {String} param.userToken 用户登录凭证
 * @param {String} param.subscriptionId 用户订阅订单ID
 * @param {Function} successCallback 请求成功时回调
 * @param {Function} failureCallback 请求失败时回调
 * @returns {*} response 请求响应结果
 */
export async function suspendUserSubscription(
  { userToken, subscriptionId },
  successCallback = "",
  failureCallback = "",
) {
  try {
    if (userToken && subscriptionId) {
      const res = await api({
        url: subscribe.suspendUserSubUrl + subscriptionId,
        method: "post",
        headers: { Authorization: userToken },
      });
      if (res.data && res.data.status) {
        if (res.data.status === 2000) {
          // 成功后同步订阅状态
          const sync = await syncUserSubscriptionState({ userToken });
          if (sync.status === "success" && sync.response) {
            // 同步订阅状态成功
            return response(
              {
                status: "success",
                message: "Suspend user subscription successfully.",
                response: sync.response,
              },
              successCallback,
            );
          } else {
            // 同步订阅状态失败
            return response(
              {
                status: "error",
                message: sync.message,
              },
              failureCallback,
            );
          }
        } else {
          return response(
            {
              status: "error",
              message: "Suspend user subscription failed.",
            },
            failureCallback,
          );
        }
      } else {
        return response({ status: "error", message: "Network error." }, failureCallback);
      }
    } else {
      return response(
        {
          status: "error",
          message: "userToken and subscriptionId was required.",
        },
        failureCallback,
      );
    }
  } catch (e) {
    return response({ status: "error", message: "Unknown error." }, failureCallback);
  }
}
/**
 * 暂停用户订阅
 * @param {Object} param 暂停用户订阅请求数据
 * @param {String} param.userToken 用户登录凭证
 * @param {String} param.subscriptionId 用户订阅订单ID
 * @param {Function} successCallback 请求成功时回调
 * @param {Function} failureCallback 请求失败时回调
 * @returns {*} response 请求响应结果
 */
export async function resumeUserSubscription(
  { userToken, subscriptionId },
  successCallback = "",
  failureCallback = "",
) {
  try {
    if (userToken && subscriptionId) {
      const res = await api({
        url: subscribe.renewUserSubUrl + subscriptionId,
        method: "post",
        headers: { Authorization: userToken },
      });
      if (res.data && res.data.status) {
        if (res.data.status === 2000) {
          // 恢复订阅成功
          // 成功后同步订阅状态
          const sync = await syncUserSubscriptionState({ userToken });
          console.log(sync);
          if (sync.status === "success" && sync.response) {
            // 同步订阅状态成功
            return response(
              {
                status: "success",
                message: "Rennew user subscription successfully.",
                response: sync.response,
              },
              successCallback,
            );
          } else {
            // 同步订阅状态失败
            return response(
              {
                status: "error",
                message: sync.message,
              },
              failureCallback,
            );
          }
        } else {
          return response(
            {
              status: "error",
              message: "Rennew user subscription failed.",
            },
            failureCallback,
            res,
          );
        }
      } else {
        return response({ status: "error", message: "Network error." }, failureCallback, res);
      }
    } else {
      return response(
        {
          status: "error",
          message: "userToken and subscriptionId was required.",
        },
        failureCallback,
        { userToken, subscriptionId },
      );
    }
  } catch (e) {
    return response({ status: "error", message: "Unknown error." }, failureCallback, e);
  }
}
export default {
  syncServerSubscriptionPlan,
  syncUserSubscriptionState,
  verifySubscriptionValidity,
  suspendUserSubscription,
  resumeUserSubscription,
};
